:root {
    --node-feed: #83A4F7;
    --node-filter: #FDAE74;
    --node-border: #E1E1E1;
    --node-export: #72E0B2;
    --node-graphic: #B86DF4;
    --node-note: #FDF7D6;
    --node-text-400: #737373;
}

.nodeHandle {
    display: block;
    background-color: #fff !important;
    border: 1px solid #A0A0A0 !important;
    width: 11px !important;
    height: 11px !important;
}

.react-flow__handle {
    position: absolute;
    background: #fff;
    border: 1px solid #A0A0A0;
    border-radius: 100%;
    height: 11px;
    width: 11px;
}

.nodeHandleLeft { left: -5px; }
.nodeHandleRight { right: -5px; }

.node {
    min-width: 300px;
    border-radius: 12px;
    overflow: hidden;
    border: 1px solid var(--node-border);
    background-color: #fff;
}

.nodeGraphic {
    min-width: 400px;
}

.nodeHead {
    height: 45px;
    color: #fff;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0 16px;
    font-size: 14px;
    font-weight: 700;
}

.exportNodeHead { background-color: var(--node-export); }
.feedNodeHead { background-color: var(--node-feed); }
.filterNodeHead { background-color: var(--node-filter); }
.graphicNodeHead { background-color: var(--node-graphic); }

.nodeData {
    height: 40px;
    border-radius: 23px;
    border: 1px solid var(--node-border) !important;
    padding: 8px 16px;
    font-size: 14px !important;
    width: 100%;
    color: var(--node-text-400) !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    background-color: var(--gray-light);
}

.nodeDataFilter:focus {
    outline: 1px solid var(--node-filter) !important;
    border: 1px solid var(--node-filter);
}

.nodeDataExport:focus {
    outline: 1px solid var(--node-export) !important;
    border: 1px solid var(--node-export);
}

.nodeDataGraphic:focus {
    outline: 1px solid var(--node-graphic) !important;
    border: 1px solid var(--node-graphic);
}

.nodeDataButton {
    padding: 0;
}

.nodeDataButtonExport:focus {
    outline: none !important;
}

.nodeSelectWrapper > div > div  {
    border: none !important;
    padding: 0 !important;
    color: var(--node-text-400) !important;
}

.nodeSelectWrapper > div > div:hover {
    background-color: var(--gray-light) !important;
}

.nodeBody {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 23px 16px;
}

.nodeFooterGraphic {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: space-between;
}

.nodeBodyRow {
    display: grid;
    align-items: center;
    gap: 0px 16px;
}

.nodeBodyRowFilter {
    grid-template-columns: 212px 115px 212px 32px;
    gap: 0px 8px;
}

.nodeBodyRowExport { grid-template-columns: 75px 1fr; }
.nodeBodyRowExportLink { grid-template-columns: 75px 290px 24px; }
.nodeBodyRowGraphic { grid-template-columns: 300px 1fr; }
.nodeBodyRowIcons { grid-template-columns: 1fr 24px; }

.nodeButton {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-size: 12px;
    font-weight: 700;
    border-radius: 26px;
}

.nodeButtonList {
    display: flex;
    gap: 16px;
    align-items: center;
}

.nodeButtonFilter {
    color: var(--node-filter) !important;
    border: 1px solid var(--node-filter) !important;
}

.nodeButtonFilter:hover {
    border-width: 2px !important;
}

.nodeButtonFilter > svg > path {
    fill: var(--node-filter);
}

.nodeButtonExport {
    color: var(--node-export) !important;
    border: 1px solid var(--node-export) !important;
}

.nodeButtonExport:hover {
    border-width: 2px !important;
}

.nodeButtonExport > svg > path {
    fill: var(--node-export);
}

.nodeButtonGraphic {
    color: var(--node-graphic) !important;
    border: 1px solid var(--node-graphic) !important;
}

.nodeButtonGraphic:hover {
    border-width: 2px !important;
}

.nodeButtonGraphic > svg > path {
    fill: var(--node-graphic);
}

.nodeButtonIcon {
    border: none !important;
    outline: none !important;
    box-shadow: none;
    padding: 0;
    width: 24px;
    height: 24px;
}

/* Export Node */

.exportNodeControlsButton {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    width: 32px !important;
    height: 32px !important;
    background-color: #fff !important;
    padding: 1px;
    border: none;
    box-shadow: none;
    border-radius: 16px !important;
}

.exportNodeDisabledButton {
    opacity: 0.5;
}

.exportNodeFeedTitle {
    font-size: 14px;
    font-weight: 700;
}

.exportNodeFeedTitleNote {
    text-align: right;
    padding: 0 1rem;
    color: var(--red-color);
    grid-column: 1 / span 2;
}

/* Dropdown */

.dropdown {
    border: none;
    box-shadow: none;
    height: 24px;
    width: 24px;
    padding: 0;
}

.dropdownOverlay {
    background-color: #fff !important;
}

.dropdownOverlay > ul {
    padding: 0 !important;
    border: 1px solid var(--nodes-editor-border-400);
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.dropdownOverlay > ul > li {
    padding: 0 !important;
}

.dropdownButton {
    --content-clr: #737373;

    display: flex;
    align-items: center;
    color: var(--content-clr);
    height: 40px;
    width: 130px;
    gap: 8px;
    transition: none !important;
    border: none;
    box-shadow: none;
}

.dropdownButton:hover {
    outline-width: 1px;
    outline-style: solid;
}

.dropdownButtonFilter:hover {
    color: var(--node-filter) !important;
    outline-color: var(--node-filter) !important;
}

.dropdownButtonFilter:hover > svg > path {
    fill: var(--node-filter);
}

.dropdownButtonGraphic:hover {
    color: var(--node-graphic) !important;
    outline-color: var(--node-graphic) !important;
}

.dropdownButtonGraphic:hover > svg > path {
    fill: var(--node-graphic);
}

/* Note Node */

.note {
    background-color: var(--node-note) !important;
    border-radius: 0;
    width: 160px;
    height: 160px;
    padding: 5px;
    border: none;
    pointer-events: none;
    font-size: 11px;
}
