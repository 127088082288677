@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&family=Prompt:wght@400;600&display=swap');

:root {
    --black-color: #181038;
    --veolet-color: #6651BD;
    --veolet-light: #cabff9;
    --veolet-hover: #F0EFF6;
    --veolet-selected: #E3E2EE;
    --veolet-blur: #C9C0EF;
    --veolet-light-blur: #F9F8FB;
    --hover-veolet: #8a77c9;
    --border-color: #E9E9E9;
    --border-gray: #E3E3E3;
    --red-color: #EA1212;
    --disabled-gray: rgba(0, 0, 0, 0.25);
    --disabled-bg: rgba(0, 0, 0, 0.04);
    --font-gray: #767676;
    --gray-light: #FAFAFA;
    --gray-color: #6B7280;
    --gray-veolet: #9892AA;
    --gray-title: #A5A2B2;
    --gray-text: #7A7A7A;
    --gray-white: #E5E7EB;
    --gray-blured: #ACACAC;
    --gray-light-bg: #F7F7F7;
    --pink-light: #FCE1E1;
    --coral-color: #C76767;
    --green-color: #2E9E2C;
    --green-bg: #5EB067;
    --green-light: #E2FCE1;
    --white-color: #FFFFFF;
}

body {
  margin: 0;
  font-family: "Mulish", "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F1F1F1;

  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: thin;  
}

  body::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
      background-color: white;
      border-radius: 0;
  }

  body::-webkit-scrollbar {
      width: 1px;
      background-color: white;
  }

  body::-webkit-scrollbar-thumb {
      border-radius: 0;
      background-color: #c7d2d4;
  }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.custom-container {
    padding: 24px 16px 16px;
    width: 100%;
    font-family: Inter, serif;
    color: var(--black-color);
}

.inner-custom-container {
    border-radius: 16px;
    border: 1px solid var(--border-color);
    background: #FFFF;
    padding: 24px 16px;
    width: 100%;
    margin: 0 auto;
}

.centered-container, .loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.centered-container {
    flex-direction: column;
    width: 100%;
    height: 90vh;
}

.loading-container {
    height: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.no-padding {
  padding: 0 !important;
}

.side-filter {
    padding: 15px !important;
    height: 100%;
    overflow-y: auto;
}

.stage-control {
    position: absolute;
    left: 20px;
    bottom: 15px;
    border-radius: 0 !important;
}

.stage-control label {
    position: relative !important;
    /*top: -50px !important;*/
    border-radius: 0 !important;
    outline: none !important;
    background-color: rgba(1,1,1,0.5);
    padding: 5px 15px;
    opacity: 0.5;
    color: white;
}

.layers-list, .add-layer {
    display: block;
    float: left;
    padding: 10px;
}

.layers-list {
    width: calc(100% - 80px);
}

.add-layer {
    width: 80px;
    height: 100%;
    background-color: rgba(1,1,1,0.7);
}

.stage-control label:first-child {
    border-radius: 100px 0 0 100px !important;
}

.stage-control label:last-child {
    border-radius: 0 100px 100px 0 !important;
}

.stage-control label:hover {
    opacity: 1 !important;
}

.input-group-prepend ~ select {
    border-radius: 0 10px 10px 0 !important;
}

/*DISABLE SELECTION*/
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.slick-prev:before, .slick-next:before {
    color: black !important;
}

.hover-appear-element {
    opacity: 0.8;
    transition: 0.4s;
}

    .hover-appear-element:hover {
        opacity: 1;
        transition: 0.4s;
    }

.scroll-panel {
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: thin;
    height: 100%;
}

    .scroll-panel::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
        background-color: white;
        border-radius: 0;
    }

    .scroll-panel::-webkit-scrollbar {
        width: 5px;
        background-color: white;
    }

    .scroll-panel::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: var(--veolet-color);
    }

.scroll-panel-h {
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: thin;
  height: 100%;
}
  
    .scroll-panel-h::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
        background-color: white;
        border-radius: 0;
    }

    .scroll-panel-h::-webkit-scrollbar {        
        background-color: white;
        height: 5px;
    }

    .scroll-panel-h::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: var(--veolet-color);
    }

.template-image {
    transition: 0.4s;
    border: 1px solid transparent;
    cursor: pointer;
    border-radius: 10px;
}

.scroll-panel-standart {

}
    .scroll-panel-standart::-webkit-scrollbar {
        width: 6px;
    }

.template-image:hover {
  transition: 0.4s;
  border: 1px solid #CCC;
}

.input-small-controls .ant-input-number-handler-wrap {
  width: 14px!important;
}

.input-small-controls .ant-input-number-handler-up-inner {
  left: 0;
}

.input-small-controls .ant-input-number-handler-down-inner {
  left: 0;
}

.default-button {    
  border: 2px solid var(--veolet-color);    
  color: var(--veolet-color);    
  height: 53px;  
  border-radius: 10px;    
  text-align: center;
  transition: 0.4s;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 0.1rem;
  margin-bottom: 1rem;
  background: white;
}

.nav-action-button {
  width: 130px;
  height: 42px;
  background: var(--veolet-color);
  border-radius: 10px;    
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  position: relative;
  margin: 0.5rem;
}

.nav-action-button:active,
.nav-action-button:focus {
  background: var(--veolet-color);
}

.nav-action-button-secondary {    
  background: #EFEFEF;
  box-shadow: 0 27px 62px -26px rgba(0, 0, 0, 0.1);
  border-radius: 10px;    
}

.nav-action-button-secondary:active,
.nav-action-button-secondary:focus {
  background: #EFEFEF;
}

.simple-button {
  height: 40px;
  width: 130px;
  border-radius: 10px;    
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-align: center;    
  cursor: pointer;
  transition: 0.4s;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background: white;
  padding: 0.5rem;
  border: 2px solid var(--veolet-color);    
  color: var(--veolet-color);  
}

.simple-button svg {
  margin-right: 0.5rem;
}

.ant-switch-checked {
  background-color: var(--veolet-color)!important;
}

.ant-radio-inner::after {
  background-color: var(--veolet-color)!important;
}

.ant-radio-checked .ant-radio-inner {
  border-color: var(--veolet-color)!important;
}

.ant-slider-handle {
  border-color: var(--veolet-color)!important;
}

.ant-slider-track {
  background-color: var(--veolet-color)!important;
}

.ant-form-item-explain-error {
    padding-left: 16px;
}


.ant-popconfirm-message {
    margin-bottom: 16px !important;
}

.ant-popconfirm-buttons {
    display: flex;
    justify-content: flex-end;
}

.ant-popconfirm-buttons > button {
    border-radius: 20px !important;
    display: flex;
    align-items: center;
    height: 28px !important;
    padding: 0 10px !important;
}

.ant-select-selector {
    background: transparent !important;
    box-shadow: none !important;
}
.ant-select-selector:hover{
    background: rgba(0, 0, 0, 0.06) !important;
}

.ant-select-single.ant-select-open .ant-select-selection-item {
    color: inherit ;
}

.ant-progress-text {
    font-weight: 700;
}

.ant-select-disabled > .ant-select-selector:hover {
    background: transparent !important;
}
.ant-select-disabled > .ant-select-arrow > svg > g > path {
    stroke: var(--disabled-gray);
}

.ant-input-number:hover .ant-input-number-handler-wrap {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.nestable-item, .nestable-item-copy {
    margin: 0;
}
.nestable .nestable-list {
    padding-left: 0 !important;
}

.nestable-list {
    margin-top: 0 !important;
}

button:focus {
    outline: none !important;
}

::-webkit-scrollbar {
    display: none;
}