@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');

.card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: 'Inter', sans-serif;
    border-radius: 16px;
    border: 2px solid var(--border-color);
    width: 236px;
    min-height: 400px;
    padding: 16px;
    background: #FFFFFF;
}

.activeCard >  .upperBlock > p {
    color: #FFFFFF;
}

.upperBlock {
    border-bottom: 1px solid var(--border-color);
    display: flex;
    flex-direction: column;
    gap: 14px;
    padding-bottom: 16px;
}

.upperBlockHeader, .upperBlockDescr {
    margin: 0;
}

.upperBlockHeader {
    color: var(--black-color);
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
}

.upperBlockDescr {
    font-size: 16px;
    color: var(--gray-color);
}

.price {
    color: var(--black-color);
    font-size: 48px;
    font-weight: 800;
    margin: 16px 0;
    line-height: 1;

}

.price > span {
    font-size: 18px;
    color: var(--gray-color);
    font-weight: 500;
}

.features {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    color: var(--black-color);
}

.feature {
    display: flex;
    gap: 8px;
    margin-bottom: 5px;
}

.feature > div {
    width: 24px;
    height: 24px;
}

.feature > span {
    font-size: 14px;
}

.activeCard {
    background: var(--veolet-color);
    color: #FFFFFF;
    border-color: var(--veolet-color);
}

.activeCard > .upperBlock > .upperBlockHeader,
.activeCard > .upperBlock > .upperBlockDescr,
.activeCard > .price,
.activeCard > .price > span,
.activeCard > .features {
    color: #FFFFFF;
}