.blocksWrapper, .maxSizesBlock {
    display: flex;
    flex-direction: column;
}

.blocksWrapper {
    gap: 20px;
}

.topBlocksWrapper {
    margin-bottom: 20px;
}

.textBlock {
    width: 100%;
    height: 70px !important;
    border-radius: 8px;
    resize: none !important;
}

.spaceBetweenBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.toggleTitle {
    font-weight: 600;
}

.input {
    height: 36px;
}

.inputSmall {
    height: 24px;
    width: 87px;
}

.maxSizesBlock {
    gap: 16px;
}

.blockTitle {
    display: block;
    margin-bottom: 16px;
}

.description {
    color: var(--gray-text);
    font-size: 12px;
    margin-top: 14px;
}

.placeholderBlock {
    margin-bottom: 10px;
}

