.switchButtonBlock, .switchButton, .closeButton {
    display: flex;
    align-items: center;
}

.switchButton, .addConditionButton, .conditionType {
    font-size: 12px;
    font-weight: 700;
}

.wrapper {
    padding-top: 32px;
}

.conditionsWrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-bottom: 24px;
    margin-bottom: 24px;
}

.addConditionButtonBlock {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0 24px;
}

.addConditionButton {
    color: var(--veolet-color);
}

.addConditionButton:hover {
    color: var(--veolet-color) !important;
}

.inputRound {
    border-radius: 23px !important ;
    height: 40px !important;
    width: 100%;
}

.inputRound > div {
    border-radius: 23px !important;
    padding: 0 15px !important;
}

.inputPadding {
    padding: 0 15px;
}

.switchButtonBlock {
    margin: 0 auto;
    width: 100px;
    border-radius: 15px;
    height: 24px;
    background: var(--veolet-blur);
}

.switchButton {
    height: 24px;
    flex-basis: 50%;
    padding: 0;
    color: var(--white-color) !important;
    justify-content: center;
    border: none;
    background: transparent;
}

.activeSwitchButton {
    border-radius: 15px;
    background: var(--veolet-color) !important;
}

.closeButton {
    justify-content: center;
}

.closeButton > span > svg {
    width: 17px;
    height: 17px;
}

.conditionType {
    height: 24px;
    max-width: 65px;
    padding: 4px 18px;
    margin: 0 auto 16px;
    border-radius: 15px;
    text-align: center;
    background: var(--veolet-color);
    color: var(--white-color);
}

.conditionTitle {
    margin-bottom: 16px;
}

.errorMessage {
    margin: 2px 0;
    color: var(--red-color);
    font-size: 12px;
}


.buttonsForGroupCondition {
    display: flex;
    flex-direction: column;
    background: var(--veolet-light-blur);
    border-radius: 6px;
}

.groupConditionButton {
    background: var(--veolet-light-blur);
    height: 40px !important;
}

.groupBlock {
    margin-bottom: 16px;
}

.conditionChildrenWrapper {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.groupBlock :global(.ant-picker-input > input) {
  font-size: 12px;
}

.groupBlock :global(.ant-picker-range-separator) {
    padding: 0 !important
}
