.block, .sideBarBlock {
    display: flex;
}

.block {
    position: relative;
}

.sideBarBlock {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 999;
}

.sideBarMini {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    background: var(--gray-light) !important;
    border-right: 1px solid var(--border-color);
    justify-content: space-between;
}

.sideBarMini, .topButtonsBlock, .organizationsBlock, .organizationsCheckboxesGroup {
    display: flex;
    flex-direction: column;
}

.sideBarMini, .topButtonsBlock {
    align-items: center;
}

.sidebarMiniBlock {
    position: fixed;
    left: 16px;
    top: 0;
    bottom: 0;
    padding: 24px 0 91px;
    display: flex;
    height: 100vh;
    flex-direction: column;
    justify-content: space-between;
}

.sideBarBig {
    padding: 24px 16px;
    background: #FFFFFF !important;
    overflow: scroll;
}

.topButtonsBlock {
    gap: 48px;
}

.circleButton {
    width: 44px !important;
    height: 44px;
}

.activeCircleButton {
    background: var(--veolet-color);
}

.activeCircleButton:hover {
    background: var(--veolet-color) !important;
}

.activeCircleButton > span > svg > path {
    fill: #FFFFFF;
}

.organizationsBlock {
    gap: 27px;
}

.checkboxesBlock, .projectsCheckboxes {
    display: flex;
    flex-direction: column;
    gap: 23px;
}

.checkboxesBlock {
    margin: 23px 0;
}

.organizationsCheckboxesGroup {
    padding-left: 13px
}

.projectsCheckboxes {
    padding-left: 16px;
}

.organizationCheckBoxItem {
    width: 192px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
}

.arrow {
    transform: rotate(180deg);
}

.arrowActive {
    transform: none;
}

.projectsButtons {
    display: flex;
    flex-direction: column;
    gap: 48px;
}

.projectsOpen {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 27px;
    font-weight: 700;
    font-size: 16px;
    padding: 4px 20px 4px 15px;
}

.content {
    margin-left: 356px;
    width: 100%;
}
.content > div{
    overflow-y: scroll;
    height: 100vh;
}

.content > div::-webkit-scrollbar {
    width: 10px;
    display: block;
    border-radius: 10px;
}

.content > div::-webkit-scrollbar-track {
    background: var(--border-color);
}

.content > div::-webkit-scrollbar-thumb {
    background: var(--gray-blured);
    border-radius: 10px;
}

.organizationsCheckbox {
    display: grid;
    grid-template-columns: 1fr 24px;
    align-items: center;
}

.dropdownButton {
    border: none;
    box-shadow: none;
    padding: 0;
    height: 22px;
}

.dropdownIcon {
    margin-bottom: 4px;
}
