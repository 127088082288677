
.billingsContainer {
    align-items: center;
    gap: 20px;
    margin: 16px 0 35px;
}

.header {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
}

.blockHeader {
    color: var(--black-color);
    font-size: 20px;
    font-weight: 700;
    display: flex;
    align-items: center;
    gap: 5px;
    margin: 0;
}

.billingsContainer, .paymentsContainer {
    display: flex;
    flex-wrap: wrap;
}

.paymentsContainer {
    gap: 16px;
    margin-top: 16px;
}

.addPaymentButton {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    max-width: 330px;
    width: 100%;
    height: 40px;
    margin-top: 22px;
    border-radius: 16px;
    color: var(--veolet-color);
    font-size: 14px;
    font-weight: 700;
}

.firstAddPayment {
    max-width: none;
    width: auto;
}

.saveButton {
    max-width: 330px;
    margin-top: 48px;
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 400px;
}

.backButton > span > svg {
    transform: rotate(180deg);
}
.backButton > span > svg > g > path {
    fill: var(--black-color);
}