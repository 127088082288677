.menu {
    position: absolute;
    z-index: 10;
    display: none;
    border-radius: 5px;
    border: 1px solid var(--border-gray);
    background: var(--white-color);
}

.menuWrapper {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 6px;
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
}

.button:hover {
    background: var(--white-color) !important;
}
