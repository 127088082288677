
.bigGap, .positionWrapper {
    display: flex;
}

.positionWrapper {
    gap: 50px;
}

.bigGap {
   gap:  20px;
}

.smallGap {
    gap: 16px;
}

.input {
    width: 64px !important;
    height: 24px;
}

.selectWrapper {
    width: 120px;
}