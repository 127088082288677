.wrapper, .cornersBlock, .bordersWrapper {
    display: flex;
    flex-direction: column;

}

.wrapper {
    gap: 16px;
}

.cornersBlock {
    gap: 8px;
}
