
.imageList, .imageBlock, .imageContainer, .imageWrapper {
    display: flex;
}

.main {
    width: 600px;
    min-height: 600px;
    background-color: rgba(249, 249, 249, 1);
    z-index: 20;
    position: fixed;
    top: 10%;
    right: 300px;
    box-shadow: 4px 0 12px #C3C4C4;
    padding: 16px;
    border-radius: 18px;
}

.imageList {
    justify-content: center;
    flex-wrap: wrap;
    overflow-x: hidden;
    overflow-y: auto;
    gap: 7px;
    max-height: 540px;
}

.imageBlock {
    flex-direction: column;
    width: 135px;
    align-items: center;
}

.imageWrapper {
    width: 70px;
    height: 70px;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.imageContainer {
    background-color: var(--white-color);
    align-items: center;
    justify-content: center;
    width: 75px;
    height: 75px;
    border: 1px solid #CCC;
    cursor: pointer;
    position: relative;
}

.popOver {
    width: 400px;
}

.deleteButton {
    position: absolute;
    top: -5px;
    right: -5px;
    width: 32px;
    height: 32px;
    background-color: var(--white-color);
    border: 1px solid var(--border-gray);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.deleteButton:hover > svg >g > path {
    fill: var(--red-color);
}

.imageName {
    font-size: 12px;
    color: var(--gray-text);
}

.emptyBlock {
    height: 500px !important;
}