
.actionButtonsWrapper {
    gap: 15px;
}

.actionButtonsTop {
    gap: 28px;
}

.input, .uploadButton {
    height: 40px;
    border-radius: 23px;
}

.openLibraryButton, .uploadButton {
    font-weight: 700;
}

.openLibraryButton {
    border: none;
    background: none;
    color: var(--veolet-color);
    font-size: 12px;
}

.uploadButton {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;
    align-items: center;
    gap: 8px;
    background: var(--veolet-color);
    color: var(--white-color)
}
.uploadButton:hover {
    color: var(--white-color) !important;
    background: var(--veolet-color) !important;

}
.uploadHandler > div {
    width: 100% !important;
}