.topBlock {
    padding-bottom: 24px;
    border-bottom: 1px solid var(--border-color);
    display: flex;
    justify-content: space-between;
}

.centerBlock {
    padding: 0 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.projectInfoBlock {
    margin-top: 50px;
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.projectNameBlock {
    display: flex;
    gap: 10px;
}

.inputWith {
    max-width: 347px;
    width: 100%;
}

.projectNameInput {
    background: var(--gray-light);
    border-radius: 20px;
    height: 40px;
}

.totalItems {
    font-size: 24px;
    font-family: 'Prompt', sans-serif;
    font-weight: 600;
}

.exportButton {
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.outputsBlock {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.buttonsBlock, .bigButtons {
    display: flex;
    align-items: center;
}

.buttonsBlock {
    gap: 24px;
}

.bigButtons {
    gap: 8px;
}

.buttonBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.goToProjectButton {
    color: var(--white-color) !important;
    background-color: var(--green-bg) !important;
    border: none;
    border-radius: 20px;
    width: 178px;
    height: 40px;
    font-weight: 700;
}
.goToProjectButton:hover {
    text-decoration: none;
}

.circleButton, .exportButton {
    border: none;
    width: 40px !important;
    height: 40px;
}

.circleButton > span > svg {
    width: 24px;
    height: 24px;
}

.scheduleSwitchBlock {
    display: flex;
    align-items: center;
    gap: 8px;
}

.scheduleBlock {
    margin: 15px 10px 0 0;
}

.scheduleTitle {
    color: var(--font-gray);
}

.scheduleInnerBlock {
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 8px;
    max-width: 312px;
}

.hourBox  {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--gray-light);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    padding: 10px;
    height: 32px;
    width: 32px;
    font-size: 16px;
}

.activeHourBox {
    background: var(--veolet-color);
    color: #FFFFFF;
}

.activeHourBox:hover {
    color: #FFFFFF !important;
    background: var(--veolet-color) !important;
}

.activeHourBox:disabled {
    color: var(--font-gray) !important;
    background: var(--veolet-color) !important;
}

.disabled {
    cursor: not-allowed;
}

.disabledButton:disabled {
    background: var(--veolet-color) !important;
    opacity: 0.5;
}

.validationBlock {
    display: flex;
    justify-content: end;
}

.validationButton {
    border: 1px solid var(--veolet-color) !important;
    border-radius: 20px;
    width: 205px;
    height: 40px;
    font-weight: 700;
    color: var(--veolet-color) !important;
    margin-top: 50px;
    background-color: #fff !important;
}

.rerunButton {
    color: var(--veolet-color) !important;
    background-color: #fff !important;
    border: 1px solid var(--veolet-color) !important;
}

.rerunButton:disabled {
    opacity: 0.5;
}

.separator {
    width: 1px;
    height: 36px;
    background-color: var(--border-color);
}