.container {
    background-color: var(--white-color);
    padding: 87px 0 40px;
}

.flexContainer, .column {
    display: flex;
    align-items: center;
}

.header, .main {
    max-width: 880px;
    margin: 0 auto;
}

.main, .upperInputsBlock, .inputsWrapper, .mappingsWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header {
    display: flex;
    align-items: center;
}

.title {
    font-size: 20px;
    font-weight: 600;
    margin: 0 calc(50% - 35px) 0 auto;
}

.main {
    padding: 0 10px;
    justify-content: center;
}

.upperInputsBlock {
    gap: 24px;
    width: 100%;
    margin-top: 45px;
}

.input, .outputInput {
    height: 40px !important;
    padding: 10px 16px;
    border: 1px solid var(--border-color);
    border-radius: 20px;
}
.input {
    width: 100%;
}

.outputInput {
    width: 347px;
}

.select > div {
    border: none !important;
}

.select > div:hover {
    border: none !important;
    background: none !important;
}

.errorSelect {
    border: 1px solid var(--red-color);
}

.errorSelect > span > span > svg {
    fill: var(--red-color);
}

.description {
    margin: 32px 0 0;
}

.centeredInput {
    max-width: 347px;
}

.selectIcon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
}

.inputsWrapper {
    width: 100%;
    margin-top: 24px;
}

.halfBlock {
    width: 100%;
    text-align: center;
}

.rightBlock {
    margin-right: 55px;
}

.grayTitle {
    color: var(--gray-text);
}

.mappingsWrapper {
    display: flex;
    flex-direction: column;
    gap: 22px;
    margin-top: 24px;
    width: 100%;
}

.mappingBlock {
    gap: 10px;
    justify-content: space-between;
    width: 100%;
}

.param {
    font-size: 12px;
}

.equalValue {
    width: 40px;
    justify-content: center;
}

.column {
    width: 347px;
}

.sourceField {
    height: 40px !important;
}

.sourceField > div {
    border-radius: 20px !important;
}

.sourceField > div > span {
    display: flex;
    align-items: center;
    padding: 10px 16px;
}

.tag {
    margin: 0 5px 0 0;
}

.actionBlock {
    width: 24px;
}

.errorIcon {
    color: var(--red-color);
    margin-right: 8px;
    width: 20px;
}

.bottomBlock {
    justify-content: space-between;
    margin-top: 10px;
    width: 100%;
    padding-right: 55px;
}

.addFieldButton, .saveButton {
    height: 40px;
    font-weight: 700;
}

.addFieldButton {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    color: var(--veolet-color);
}

.addFieldButton > span {
    margin-inline-end: 0 !important;
}

.addFieldButton:hover {
    color: var(--veolet-color) !important;
    background: var(--veolet-hover) !important;
}

.saveButton {
    width: 145px;
    border-radius: 18px;
    background: var(--veolet-color);
    color: var(--white-color);
}

.saveButton:hover {
    color: var(--white-color) !important;
    background: var(--veolet-color) !important;
}

.emptyFeedTemplateIcon {
    font-size: 30px;
    margin-top: 20px;
}
