.radioBlock {
    display: flex;
    justify-content: space-between;
}
.radioItem {
    width: 90px;
    margin: 0 !important;
}

.relativePositionBlock {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}