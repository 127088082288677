.mainBlock {
    border-top: 1px solid var(--border-color);
    padding: 24px 0 0;
}
.topBlock {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.deleteUsersButton {
    color: var(--red-color);
    font-weight: 700;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0 5px;
}

.addUserBlock {
    width: 100%;
}

.addUserForm {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
}

.addUserInput {
    max-width: 347px;
    width: 100%;
    height: 40px;
    border-radius: 20px;
    border: 1px solid var(--border-color);
    background: var(--gray-light);
    padding: 11px 16px;
}

.sendButton {
    display: flex;
    align-items: center;
    gap: 10px;
}