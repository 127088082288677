.login-container {
    height: 100%;
    width: 100%;
    margin: auto !important;
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0 10px !important;
}

.login-panel {
    background: white;
    border-radius: 10px;
    margin: 0 !important;
    display: block !important;
    max-width: 550px;
    width: 100%;
    padding: 2rem !important;
}

.login-panel > form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.login-logo-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.login-logo-div > img {
    width: 100%;
    height: auto;
    max-width: 150px;
}

.login-input-label > .ant-form-item-row > .ant-form-item-control {
    flex: none;
}

.login-logo-label {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 50px;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--veolet-color);
}

form > .ant-form-item {
    margin-bottom: 0;
}

.login-input-label > .ant-form-item-row > .ant-form-item-label > label {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    color: #C6C6C6;
    margin-bottom: 0;
}

.login-input-label > .ant-form-item-row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.login-input-label > .ant-form-item-row > div {
    width: 100%;
    display: flex;
}

.login-input-field {
    background: #F2F3F6 !important;
    border-radius: 10px;
    border: none;
    height: 50px;
    color: #475569;
}

.login-input-field > input {
    background: #F2F3F6;
}

.login-button-block {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;
}
.login-divider {
    margin: 0 -2rem;
}

.login-divider > div {
    margin: 0 !important;
}

.login-button {
    min-width: 190px;
    background: var(--veolet-color);
    font-weight: 600;
    line-height: 18px;
    color: #FFFFFF;
}

.bottom-block {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0 -1rem;
    gap: 10px;
}

.bottom-block > span {
  margin:  0 0.5rem
}

.login-link-gray {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #D2D6DB;
}

.login-link-gray:hover {
    color: var(--veolet-color);
}

.login-link-color {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: var(--veolet-color);
}

.login-simple-text {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
}
.violet-link-register {
    color: var(--veolet-color);
}

.registration-page-login-link{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 1rem;
    margin-bottom: -1rem;
}


.registration-input-field {
    height: 40px;
    background: #F2F3F6;
    border-radius: 10px;
    border: unset;
    font-size: 16px;
    color: #475569;
}

.submit-registration-button-block{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.registration-confirm-icon > svg > path {
    fill: var(--veolet-color);
}

.reset-password-text {
    max-width: 400px;
    margin-top: 20px;
    font-size: 14px;
    text-align: center;
    color: var(--gray-text) !important;
}

.reset-password-form {
    margin-top: 20px;
}

.error-alert-block {
    margin-top: 15px;
}