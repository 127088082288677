.block {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 80vh;
}
.backButton {
    width: 200px;
    margin-top: 20px;
}