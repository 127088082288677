.toggleContainer, .dialogButton {
    border-radius: 22px;
}

.toggleContainer {
    background-color: #FFFFFF;
    border: 1px solid var(--border-color);
    user-select: none;
    position: relative;
    display: flex;
    align-items: center;
    padding: 0;
}

.underToggleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.toggleContainer, .underToggleWrapper {
    width: 352px;
    height: 44px;
    padding: 8px 0;
}

.underToggle, .dialogButton {
    width: 176px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.underToggle > svg > path {
    fill: var(--black-color);
    width: 24px;
}

.underToggle {
    cursor: pointer;
}

.toggleContainer:hover > .underToggleWrapper > .underToggle > svg > path {
    fill: var(--hover-veolet);
    transition: all 0.2s;
}

.toggleContainer:disabled > .underToggleWrapper > .underToggle > svg > path {
    fill: var(--disabled-gray);
}

.dialogButton {
    height: 42px;
    padding: 8px 0;
    background-color: var(--veolet-color);
    color: #FFFFFF;
    min-width: unset;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 174px;
    transition: all 0.4s ease;
}

.dialogButton > svg > path {
    fill: #FFFFFF;
}

.active {
    left: 0;
}

.disabled, .disabledBackground {
    cursor: not-allowed;
}

.disabledBackground {
    background: var(--veolet-light);
}

.disabled > svg > path {
    fill: var(--disabled-gray);
}

