.sidebar {
    position: fixed;
    top: 75px;
    right: 0;
    z-index: 14;
    width: 290px;
    height: 100%;
    background: var(--white-color);
    border-left: 1px solid var(--border-gray);
    padding-bottom: 95px;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.headerBlock {
    padding: 24px 24px 0;
}