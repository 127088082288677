.block {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.rightBlock, .buttonBlock {
    display: flex;
    align-items: center;
}

.rightBlock {
    gap: 16px;
}
.buttonBlock {
    justify-content: space-between;
    gap: 10px;
}
.goButton {
    color: #FFFFFF;
}