.topBlock {
    margin-bottom: 21px;
    display: flex;
    align-items: center;
    gap: 24px;
}

.organizationsOfProjectsBlock {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.organizationOfProject {

}

.projectsBlock {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.topBlock, .backButton {
    display: flex;
    align-items: center;
}

.backButton {
    justify-content: center;
    font-weight: 700;
    font-size: 15px;
    text-transform: uppercase;
}

.backButton > span > svg {
    transform: rotate(180deg);
}

.backButton > span > svg > g > path {
    fill: var(--black-color);
}

.searchInput {
    margin-bottom: 28px;
}

.addProjectButton {
    border-radius: 20px;
    font-weight: 700;
    color: var(--white-color);
    background: var(--veolet-color);
}

.addProjectButton:hover {
    color: var(--white-color) !important;
    background: var(--hover-veolet) !important;
}

.addProjectButton:disabled:hover {
    color: var(--disabled-gray) !important;
    background: var(--disabled-bg) !important;
}