
.imageOption {
    display: flex;
    align-items: center;
    gap: 5px;
}

.label {
    margin: 10px 0;
    color: var(--gray-text);
}