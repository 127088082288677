.circle {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    border: 1px solid #999;
    background-color: #CCC;
    cursor: pointer;
}

.circle-selected {
    border: 1px solid #11D4FE;
}

.circle:hover {
    border-width: 2px;
}