.sessionsCard {
    display: grid;
    grid-template-columns: 1fr 0.3fr 0.3fr 0.3fr 0.5fr;
    gap: 35px;
    padding: 24px 0;
}

.borderBottom {
    border-bottom: 1px solid var(--border-color);
}

.sessionsBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
    text-align: center;
}

.sessionsBlockSchedule {
    text-align: left;
}

.scheduleTitle {
    color: var(--gray-veolet);
}

.scheduleDate {
    font-weight: 500;
    font-size: 18px;
}

.sessionValue {
    font-size: 18px;
    font-weight: 500;
    color: var(--veolet-color);
}

.sessionsButton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.sessionsButton > button {
    max-width: 205px;
    width: 100%;
    height: 40px;
    border-radius: 20px;
    border-color: var(--veolet-color);
    border: none;
    box-shadow: none;
    color: var(--veolet-color);
    font-weight: 700;
}

