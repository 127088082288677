.fonts-editor-font-item:hover {
    /* background-color: whitesmoke; */
}

.fonts-editor-font-item .fonts-editor-font-item-delete {
    opacity: 0;
    transition: 0.5s;
}

.fonts-editor-font-item:hover .fonts-editor-font-item-delete {
    opacity: 1;
    transition: 0.5s;
}

.fonts-editor-font-item-delete-icon {
    opacity: 0.9;
}

    .fonts-editor-font-item-delete-icon:hover {
        opacity: 1;
    }