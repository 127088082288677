:root {
    --card-border-radius: 8px;
}

.main {
    --add-template-button-bg: #ECEAF8;
    
    position: fixed;
    z-index: 100;
    left: var(--aside-width);
    top: 0;
    height: 100vh;
    width: 240px;
    background: #fff;
    border-right: 1px solid var(--nodes-editor-border-400);
    display: flex;
    flex-direction: column;
    padding: 33px 16px;
    overflow: scroll;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 30px;
}

.headerText {
    font-size: 14px;
    font-weight: 600;
}

.headerCloseButton {
    border: none;
    box-shadow: none;
    padding: 0;
}

.headerCloseButton > svg {
    height: 15px;
    width: 15px;
}

.headerCloseButton > svg > g > path {
    stroke-width: 2px;
}

.addNewTemplateButton {
    border: 1px dashed var(--aside-active-button);
    background-color: var(--add-template-button-bg);
    box-shadow: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 208px;
    height: 135px !important;
    padding: 43px 0;
    gap: 8px;
    margin-bottom: 24px;
}

.addNewTemplateButton > span {
    color: var(--aside-active-button);
    font-size: 14px;
    font-weight: 700;
}

.addNewTemplateButton > svg {
    fill: var(--aside-active-button);
}

.templateWrapper {
    margin-bottom: 24px;
    padding-bottom: 21px;
    border-bottom: 1px solid #F1F1F1;
    cursor: pointer;
}

.template {
    position: relative;
    width: 208px;
    height: 208px;
}

.templateImage {
    overflow: hidden;
    border-radius: 8px;
    outline: 1px solid var(--nodes-editor-border-400) !important;
    cursor: pointer;
}

.templateTooltip {
    position: absolute;
    top: 0;
    width: 100%;
    height: 168px;
}

.templateControls {
    position: absolute;
    background-color: #FFFFFFB8;
    z-index: 1;
    width: 100%;
    bottom: 0;
    padding: 13px 8px;
}

.controlsButton {
    border: none;
    box-shadow: none;
    padding: 15px 15px;
    margin: 0;
    color: #737373;
    font-size: 12px;
    height: 42px;
    line-height: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.controlsButton:hover > svg > path {
    fill: var(--hover-veolet) !important;
}

.templateDropdown > ul {
    padding: 0 !important;
    border: 1px solid var(--nodes-editor-border-400);
    width: 90px;
}

.templateDropdown > ul > li {
    padding: 0 !important;
}

.templateDropdownButton {
    --content-clr: #000;

    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent !important;
    border: none;
    color: var(--content-clr);
    width: 36px;
    height: 24px;
    padding: 0;
    box-shadow: none;
}

.templateDropdownButton > span > svg {
    fill: var(--content-clr);
    font-size: 18px;
    stroke-width: 2px;
}

.templateName,
.templateText {
    font-size: 14px;
    line-height: 14px;
}

.templateName {
    font-weight: 600;
}

.templateInfo {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 8px;
}

.disableCursor {
    cursor: not-allowed;
}