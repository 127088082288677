.status {
    border-radius: 30px;
    background:var(--green-light);
    width: 108px;
    height: 28px;
    color: var(--green-color);
    font-weight: 700;
    text-transform: capitalize;
}

.status, .invoice {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.invoice {
    color: var(--veolet-color);
    font-weight: 700;
    gap: 8px;
}

.disabled  > svg >  g > path {
 fill: #9E9E9E
}