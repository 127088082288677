
.wrapper, .positionsBlock, .measureBlock, .controlsBlock, .inputsBlock {
    display: flex;
}

.measureBlock, .measureTitle, .smallLabel {
    font-size: 12px;
}

.wrapper {
    flex-direction: column;
    gap: 10px;
}

.disabledStyles {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    background: var(--white-color);
    opacity: 0.5;
    cursor: not-allowed;
}

.title {
    color: var(--gray-text);
}

.positionsBlock {
    align-items: flex-end;
    justify-content: space-between;
}

.controlsBlock {
    gap: 15px;
}

.smallLabel {
    color: var(--gray-text);
}

.measureBlock {
    margin-left: 5px;
}

.measureTitle {
    margin: 0 5px;
}

.inputsBlock {
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.input {
    width: 70px;
}