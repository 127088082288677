.projectReport {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 1rem;
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #fff;
  z-index: 1000;
  overflow: scroll;
}

.projectReportClosed {
  display: none;
}

.buttonContainer {
  position: fixed;
  width: 100vh;
  top: 0;
  background-color: #fff;
  padding-top: 1rem;
}

.button {
  border: none;
  box-shadow: none;
  background-color: transparent;
}

.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}