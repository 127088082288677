:root {
  --content-color: #9892AA;
}

.searchWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.container {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 350px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid var(--border-color);
  padding: 10px 16px;
  background-color: #fff;
}

.input {
  border: none;
  background: transparent;
  width: 100%;
  color: var(--content-color);
  margin: 0;
  padding: 0;
}

.icon > path {
  fill: var(--content-color);
}

.reset {
  border: none;
  outline: none;
  box-shadow: none;
  padding: 0 !important;
}

.resetIcon {
  width: 28px;
  height: 28px;
}

.resetIcon > g > rect {
  fill: none;
}

.resetIcon > rect {
  width: 2px;
  fill: var(--content-color);
}

.searchButton {
  background: var(--veolet-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchButton:hover {
  background: var(--veolet-color) !important;
}

.searchButton > span > svg > path {
  fill: var(--white-color);
}
