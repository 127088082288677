.containerColumn, .fontsSettingsInner, .textDecorationBlock, .radioGroup {
    display: flex;
}

.textDecorationBlock {
    width: 90px;
}

.containerColumn {
    flex-direction: column;
}

.block {
    padding: 24px !important;
}

.title {
    margin-bottom: 16px;
}

.fontsSettingsBlock {
    gap: 16px;
}

.fontsSettingsPadding {
    padding-top: 25px;
}

.fontsSettingsInner {
    justify-content: space-between;
}

.fontsSettingsInner > span {
    color: var(--gray-text);

}

.fontsBlock {
    gap: 5px;
}

.fontInput {
    width: 85px;
}

.fontInput > span {
    display: block;
    margin-right: 8px !important;
}

.textDecorationBlock {
    justify-content: center;
}

.textDecoration, .radioButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px !important;
    height: 24px !important;
}

.textDecoration {
    padding: 0;
    border-radius: 4px;
}

.activeTextDecoration {
    background: var(--veolet-color) !important;
}

.activeTextDecoration > span > svg > path {
    fill: var(--white-color);
}

.activeTextDecoration > span > svg > g > path {
    fill: var(--white-color);
}

.underlineButton {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.strikeButton {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.alignmentBlock {
    align-items: center;
}

.radioGroup {
    gap: 4px;
}

.radioButtonLeft {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.radioButtonRight {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.positionControlBlock {
    margin-top: 24px;
}


