.select > div {
    border: none !important;
}
.select {
    width: 100%;
}

.selectIcon {
    transform: rotate(180deg);
}

.activeSelectIcon {
    transform: none;
}

.accessButton {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
}

.accessButton > span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.accessButton:disabled > span {
    color: var(--disabled-gray);
}
.accessButton:disabled > div > svg > g > path{
    stroke: var(--disabled-gray);
}

.accessCheckbox {
    display: flex;
    flex-direction: column;
    max-height:190px;
    overflow: scroll;
    gap: 14px;
}

.accessColumn {
    max-width: 380px;
}