.button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    border: none;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 700;
    color: #FFFFFF;
    cursor: pointer;
    box-shadow: none;
}


.buttonWithIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
