
.closeIcon, .button, .containerRow {
    display: flex;
    justify-content: center;
    align-items: center;
}

.container {
    display: flex;
    position: absolute;
    z-index: 1;
}

.modal {
    width: 370px;
    padding: 16px;
    border-radius: 5px;
    background-color: var(--white-color);
    border: 1px solid var(--border-gray);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.closeIcon {
    width: 24px !important;
    height: 24px !important;
    padding: 0;
}

.labelText {
    color: var(--gray-text);
}

.modalBg {
    z-index: 100;
    width: 100vw;
    height: 100vh;
    padding: 25px;
    background-color: rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 0;
    left: 0;
}

.headerBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;
}

.input, .smallInput {
    background: var(--gray-light);
    color: var(--black-color);
}

.input {
    height: 40px !important;
}

.smallInput {
    width: 75px;
    height: 24px !important;
    border-radius: 8px !important;
    display: flex;
    align-items: center;
}

.smallInput > div > input {
    padding: 5px !important;
}

.selectBlock {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 16px;
}

.measureBlock {
    display: flex;
    align-items: center;
    gap: 8px;
}

.button {
    width: 100px;
    height: 30px;
    border-radius: 30px;
    font-weight: 700;
    border: 1px solid var(--border-color);
    color: var(--gray-color);
    background: var(--white-color);
}

.buttonOk {
    background: var(--veolet-color);
    color: var(--white-color);
}

.containerRow {
    margin-top: 25px;
    gap: 20px;
}

.bottomBlock {
    display: flex;
    align-items: center;
    margin-top: 32px;
    justify-content: flex-end;
    gap: 10px;
}
