.bottomBorderDivider {
    border-bottom: 1px solid var(--border-gray);
    padding: 14px 24px;
}

.bottomBigPaddings {
    padding: 24px 24px 32px;
}

.smallTitle {
    font-weight: 600;
}

.text {
    color: var(--gray-text);
}

.input {
    border-radius: 8px !important;
    border-color: var(--border-color);
}

.input > span {
    margin: 0;
}

.selectBlock {
    display: flex;
    flex-direction: column;
}

.select {
    height: 36px !important;
}

.select > div {
    border-radius: 8px !important;
}

.selectError > div {
    border-color: red !important;
}

.activeIcon {
    transform: rotate(180deg);
}

.spaceBetweenBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.innerBlock {
    padding-top: 16px;
}

.gapBlock {
    gap: 16px;
}
.bigGapBlock {
    gap: 24px;
}
.openBlock {
    height: 32px;
}
