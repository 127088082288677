.container {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    display: flex;
    flex-direction: column;
}

.containerRow {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    margin: 5px 0;
    display: flex;
    flex-direction: row;
    flex: 1;
}

.label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 12px;
    margin: 5px 5px 5px 0;
}

.selectDiv {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex: 1;
}

.input {
    font-size: 14px;
    width: 100%;
    border: 1px solid #D8D8D8;
    border-radius: 2px;
}

.select {
    font-size: 12px;
    width: 100%;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    text-align: left;
}
