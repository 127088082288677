.flexBetweenBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.planBlock {
    padding: 21px 0;
    border-bottom: 1px solid #F3F4F6;
}

.planBlock:first-child {
    padding-top: 0;
}

.planTitle {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 20px;
    font-weight: 700;
}

.planTitle > svg {
    cursor: pointer;
}

.badge {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    height: 22px;
    padding: 0 10px;
    background: var(--green-light);
    color: var(--green-color);
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
}

.renewalDate {
    color: var(--gray-color);
}

.price {
    color: var(--veolet-color);
    font-size: 20px;
    font-weight: 700;
}

.generationsBlock {
    margin: 32px 0 24px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.generationsDescription {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.generationsText {
    font-weight: 500;
}

.generationsText > span, .benefitTitle {
    font-weight: 400;
    color: var(--gray-color);
}

.benefitsBlock {
    margin-top: 16px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
}

.notBenefitTitle {
    text-decoration: line-through;
}

.benefitBlock {
    display: flex;
    align-items: center;
    gap: 5px;
}

.benefitBlock > svg {
    min-width: 24px;
}

.notBenefitBlock > svg > path {
    stroke: var(--gray-color);
}

.buttonsBlock, .buttonsLeftBlock {
    display: flex;
    width: 100%;
}

.buttonsBlock {

    justify-content: space-between;
    margin-top: 24px;
}

.buttonsLeftBlock {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    max-width: 360px;
}

.renewSubscriptionButton {
    width: 178px;
    background: #FFFFFF !important;
    color: var(--veolet-color) !important;
    border: 1px solid var(--veolet-color) !important;
}
