.wrapper {
    margin-top: 22px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    border: 1px solid;
    border-radius: 6px;
    padding: 12px;
}

.textBlock {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    gap: 3px;
}

.title, .description {
    color: inherit;
}

.title {
    font-weight: 600;
    text-transform: uppercase;
}


