.wrapper {
    display: flex;
    align-items: center;
    gap: 5px;
    width: 100%;
}

.boxBlock {
    width: 22px;
    height: 22px;
    padding: 1px;
    cursor: pointer;
    box-sizing: content-box;
    text-align: start;
}

.boxItem {
    width: 22px;
    height: 22px;
    border: 1px solid var(--border-gray);
    border-radius: 16px;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAADFJREFUOE9jZGBgEGHAD97gk2YcNYBhmIQBgWSAP52AwoAQwJvQRg1gACckQoC2gQgAIF8IscwEtKYAAAAASUVORK5CYII=") left center;
}

.colorPicker {
    position: fixed;
    top: 35%;
    right: 300px;
    z-index: 10;
}

.colorEditor {}

@supports (font: -apple-system-body) and (-webkit-appearance: none) {
    .colorEditor {
        position: relative;
    }
    .colorPicker {
        position: absolute;
        top: 30px;
        right: 0;
        z-index: 999
    }
}

.cover {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.title {
    font-size: 12px;
}
