.block, .dataBlock, .inputsBlock {
    display: flex;
}

.block {
    flex-direction: column;
    gap: 20px;
}

.dataBlock {
    justify-content: space-between;
    gap: 50px;
}

.inputsBlock {
    justify-content: flex-start;
    gap: 16px;
}

.label {
    width: 10px;
    justify-content: center;
}

.input {
    width: 64px !important;
    height: 24px;
}