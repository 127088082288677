.card {
    width: 331px;
    height: 112px;
    background: var(--gray-light);
    border-radius: 16px;
    border: 1px solid var(--border-color);
    padding: 15px 15px 10px 15px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.cardActive {
    border-color: var(--veolet-color);
}

.leftBlock {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 27px;
}

.paymentInfoWrapper {
    position: relative;
    display: flex;
    gap: 10px;
}

.paymentInfoWrapper::before {
    content:  '';
    width: 20px;
    height: 20px;
    border: 2px solid var(--border-color);
    border-radius: 50%;
}

.paymentInfoWrapperActive::before {
    border: 5px solid var(--veolet-color);
}

.title {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
}

.title > span {
    text-transform: capitalize;
}

.subtitle {
    color: var(--gray-color);
    font-size: 12px;
    margin: 0;
}

.buttonsWrapper {
    display: flex;
    align-items: center;
    cursor: default;
}

.buttonsWrapper > div {
    width: 2px;
    height: 15px;
    background: var(--gray-white);
    margin: 0 10px;
}

.buttonsWrapper > button {
    padding: 0;
}

.button {
    display: flex;
    align-items: center;
    gap: 5px;
    color: var(--font-gray);
    font-weight: 500;
    font-size: 14px;
    position: relative;
    height: 21px;
}

.button:disabled > svg > g > path {
    fill: var(--disabled-gray);
}

.paymentImg {
    display: flex;
    align-items: center;
}

.loading {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

