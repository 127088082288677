.organizationBlock {
    display: flex;
    align-items: center;
    gap: 10px;
}

.organizationBlock > h4 {
    max-width: 400px;
    font-size: 24px;
    font-weight: 700;
    margin: 0;
}
.organizationNameForm {
    display: flex;
    gap: 10px;
}

.input, .submitButton {
    border: none;
    background: transparent;
    height: 40px;
}

.input {
    width: 350px;
}

.submitButton {
    width: 40px !important;
    box-shadow: none;
}