:root {
  --default-status-bg: #EAE1FC;
  --default-status-clr: #6651BD;
  --success-status-bg: #E2FCE1;
  --success-status-clr: #5EB067;
  --error-status-bg: #FCE1E1;
  --error-status-clr: #C76767;
  
  --project-fz: 14px;
  --project-height: 28px;

  --output-fz: 12px;
  --output-height: 24px;
}

.statusBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 30px;
  font-weight: 700;
}

/* Dimensions */

.project.statusBlock {
  font-size: var(--project-fz);
  height: var(--project-height);
  width: fit-content;
  padding: 3px 22px;
}

.output.statusBlock {
  font-size: var(--output-fz);
  height: var(--output-height);
  width: fit-content;
  padding: 3px 18px;
}

/* Colors */

.default.statusBlock {
  background-color: var(--default-status-bg);
  color: var(--default-status-clr);
}

.success.statusBlock {
  background-color: var(--success-status-bg);
  color: var(--success-status-clr);
}

.error.statusBlock {
  background-color: var(--error-status-bg);
  color: var(--error-status-clr);
}

.percentInit > span {
    color: var(--success-status-clr) !important;
}

.percentInProgress > span {
    color: var(--default-status-clr) !important;
}