
.button, .uploadButton > div, .uploadButton > div > span {
    width: 100% !important;
    display: flex !important;
    align-items: center;
    justify-content: center !important;
}

.button {
    flex-direction: row-reverse;
    gap: 8px;
    font-weight: 700;
    color: var(--veolet-color);
}

.button:hover {
    color: var(--veolet-color) !important;
}

.button > span {
    margin: 0;
}

.uploadButton {
    width: 100% !important;
}


