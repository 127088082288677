.control-panel-button {
    background-color: white;
    outline: none !important;
    border: none;
    padding: 5px 8px;
    border-right: 1px solid #ccc;
    max-width: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.control-panel-button > button:disabled {
    border: none;
    background: none;
    max-width: 33px;
    padding: 0;
}

.control-panel-button:hover {
    background-color: whitesmoke;
}

.control-panel-button:active {
    outline: none !important;
    background-color: #CCC;
}
.control-panel-main {
    position: absolute;
    z-index: 1;
    bottom: 40px;
    display: flex;
    flex-direction: row;
    background-color: white;
    box-shadow: 0 0 2px 1px rgb(0 0 0 / 8%);
    left: calc(50% - 100px);
}