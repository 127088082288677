.productTable {
  width: 100%;
  border: 0.5px solid #eaeaea;
  overflow-y: scroll;
}
.productTable::-webkit-scrollbar {
    width: 5px;
    display: block;
    border-radius: 10px;
}

.productTable::-webkit-scrollbar-track {
    background: var(--border-color);
}

.productTable::-webkit-scrollbar-thumb {
    background: var(--gray-blured);
    border-radius: 10px;
}

.productRow {
  display: grid; 
  grid-template-columns: 150px 1fr; 
}

.productField, .draggableProductItem {
  padding: 7px 8px;
}

.productField {
  border: 0.5px solid var(--border-color);
}

.productField > div {
  max-width: 197px;
  font-size: 14px;
  line-height: 1;
  overflow: hidden;
  white-space: break-spaces;
  text-overflow: ellipsis;
}

.draggableProductField {
  padding: 8px;
}

.draggableProductItem {
  cursor: grab;
  border: 1px dashed var(--border-gray);
  border-radius: 2px;
  background: var(--gray-light-bg);
}

.productName {
  cursor: default ;
}

.productTag {
  display: inline-block;
  position: relative;
  top: -6px;
  color: var(--white-color);
  text-align: center;
  font-size: 8px;
  font-weight: 700;
  padding: 0 8px;
  border-radius: 7px;
  margin-left: 4px;
}

.productTagStr {
  background-color: #F7AD83;
}

.productTagNum {
  background-color: #62C979;    
}

.productTagAuto {
  background-color: #8192be;    
}

.productTagAny {
  background-color: var(--veolet-color);    
}