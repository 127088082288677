.wrapper {
    position: fixed;
    z-index: 2;
    top: 75px;
    left: 0;
    width: 320px;
    height: 100%;
    padding: 24px 9px 30px;
    overflow: auto;
    background: var(--white-color);
    border-right: 1px solid var(--border-gray);
}

.header {
    margin-bottom: 28px;
    padding: 0 7px 0 25px;
}

.layersElement, .elementBlock, .nameElement, .buttonsBlock, .iconBlock {
    display: flex;
    align-items: center;
}

.layersElement {
    gap: 9px;
    padding: 10px 7px 10px 25px;
    cursor: pointer;
}

.layersElement:hover {
    background: var(--veolet-hover);
}

.selectedElement {
    background: var(--veolet-hover);
}

.selectedElement:hover {
    background: var(--veolet-selected)
}

.selectedElementInGroup {
    background: var(--veolet-selected);
}

.elementBlock {
    width: 100%;
    justify-content: space-between;
}

.nameElement {
    display: flex;
}

.elementBlock:hover > div > .editButton {
    opacity: 1;
}

.buttonsBlock {
    gap: 5px;
}

.iconBlock {
    width: 32px;
    justify-content: center;
}

.editButton {
    opacity: 0;
}

.childrenElement {
    padding-left: 45px;
}

.elementCollapsed {
    display: none;
}

.sideButton {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 0 !important;
    width: 24px !important;
}

.arrowDown {
    transform: rotate(180deg);
}

.arrowDown > g > path {
    stroke: var(--gray-blured);
}

.arrowUp {
    transform: rotate(0deg);
}

.input {
    width: 100%;
}