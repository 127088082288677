.topInnerBlock {
    display: flex;
    justify-content: space-between;
    gap: 25px;
}

.paymentsBlock {
    max-width: 332px;
    width: 100%;
}

.paymentsBlockDisabled {
    opacity: 0;
    pointer-events: none;
}

.paymentsCardsWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 26px;
    width: 100%;
    max-height: 360px;
    overflow-y: scroll;
}

.addPaymentButton {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin-top: 8px;
    width: 100%;
    height: 40px;
    border-radius: 16px;
    color: var(--veolet-color);
    font-weight: 700;
}

.bottomInnerBlock {
    border-top: 1px solid #F3F4F6;
    margin-top: 16px;
    padding-top: 24px;
}

.billingToggleButton {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
}

.billingToggleButton:disabled > svg > g > path {
    stroke: var(--disabled-gray);
}

.downIcon {
    transform: rotate(180deg);
}

.blockTitle {
    font-size: 20px;
    font-weight: 700;
}
