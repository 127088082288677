.borderBottom {
    border-bottom: 1px solid var(--border-color);
}
.projectCard {
    border: 1px solid var(--border-color);
    border-radius: 16px;
    padding: 16px;
}

.topBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;
}

.projectInfo {
    display: flex;
    flex-direction: column;
    max-width: 350px;
    width: 100%;
}

.projectName, .projectItems {
    font-family: 'Prompt', sans-serif;
}

.projectName {
    font-size: 18px;
    color: #585858;
}

.projectItems {
    font-size: 16px;
    font-weight: 600;
    color: var(--black-color);
}

.statusBlock {
    max-width: 240px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
}

.actionButtonsBlock {
    display: flex;
    justify-content: flex-end;
    width: 160px;
    gap: 24px;
}

.actionButtonsBlock > button {
    border: none;
}

.actionButton {
    padding: 0 !important;
    width: 24px !important;
    height: 24px;
    min-width: 0;
}

.disabledActionButton,
.disabledBlock {
    opacity: 0.5;
    pointer-events: none;
}

.bottomBlock {
    padding-top: 19px;
}

.moreDetailedButton {
    display: flex;
    align-items: center;
    gap: 7px;
    color: var(--gray-veolet);
    border: none;
    background: none;
    padding: 0;
}

.moreDetailedButton > svg {
    transform: rotate(180deg);
}

.moreDetailedButton > svg > g > path {
    stroke: var(--gray-veolet);
}

.moreDetailedButtonOpen > svg {
    transform: none;
}

.previewBlock {
    margin: 32px 0 0;
}

.sliderBlock {
    position: relative;
    height: 136px;
}

.slidesLoading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 136px;
}

.scrollBlock {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    gap: 14px;
    scrollbar-width: thin;
    width: 100%;
    overflow-x: scroll;
    white-space: nowrap;
    padding: 0 0 24px;
}

.outputImagesBlock {
    padding: 28px 0 24px;
}

.previewInnerBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 55px 0 24px;
}

.previewInnerTitle, .previousUpload {
    font-size: 18px;
    font-weight: 600;
}
.previewInnerTitle {
    color: var(--veolet-color);
    margin: 0;
}

.outputLogsButton {
    height: 40px;
    border: none;
    box-shadow: none;
    color: var(--veolet-color);
    font-size: 17px;
    font-weight: 600;
}

.scheduleBlock {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-bottom: 24px;
}
.scheduleTitle {
    color: var(--gray-veolet);
}

.scheduleDate {
    font-weight: 500;
    font-size: 18px;
}

.sessionBlock {
    padding: 24px 0 0;
}

.validationLogsWrapper {
    max-height: 240px;
    overflow-y: scroll;

}
.validationLogsWrapper::-webkit-scrollbar {
    width: 5px;
    display: block;
    border-radius: 10px;
}

.validationLogsWrapper::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.validationLogsWrapper::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

.previousUpload {
    color: var(--gray-veolet);
}


.sessionsBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
}
