:root {
    --aside-width: 64px;
    --aside-active-button: #6651BD;
    --nodes-editor-clr-filter: #FDAE74;
    --nodes-editor-clr-graphic: #B86DF4;
    --nodes-editor-clr-export: #72E0B2;
    --nodes-editor-clr-note: #FDF7D6;
    --nodes-editor-clr-save: #5EB067;
    --nodes-editor-clr-run: #6651BD;
    --nodes-editor-border-400: #E3E3E3;
}

.sidebarButton {
    cursor: pointer;
    transition: 0.4s;
    border-radius: 9999px;
    height: 44px !important;
    width: 44px !important;
    display: grid;
    place-items: center;
    padding: 0;
}

.sidebarButton:hover {
    transition: 0.4s;
    box-shadow: 0 15px 25px -10px #0000001A;
}

.sidebarButtonActive,
.sidebarButtonActive:hover {
    background: var(--aside-active-button) !important;
}

.dndflow {
    display: flex;
    height: 100%;
    width: 100%;
}

.reactflowWrapper {
    width: 100%;
    height: 100%;
    background-color: white;
}

.aside {
    width: var(--aside-width);
    box-shadow: 0px 4px 4px 0px #0000000F;
    border-right: 1px solid var(--nodes-editor-border-400);
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    padding: 24px 10px;
    background: #FFFFFF no-repeat center 100px;
}

.asideButtons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 74px;
}

.panel {
    position: fixed;
    z-index: 90;
    bottom: 52px;
    right: 50%;
    transform: translateX(50%);
    box-shadow: 0px 1px 10px 0px #97979740;
    height: 90px;
    padding: 9px 35px;
    border-radius: 40px;
    border: 1px solid var(--nodes-editor-border-400);
    overflow: hidden;
    display: flex;
    gap: 24px;
    background-color: #fff;
}

.panelNodes {
    display: flex;
    gap: 8px;
    position: relative;
    bottom: -24px;
}

.node {
    cursor: grab;
    height: 62px;
    overflow: hidden;
    width: 150px;
    border-radius: 11px;
    border: 1px solid var(--nodes-editor-border-400);
}

.nodeNote {
    background-color: var(--nodes-editor-clr-note);
}

.nodeNote > div {
    color: #000;
}

.nodeHeader {
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    padding: 7px 15px;
}

.nodeHeaderFilter { background-color: var(--nodes-editor-clr-filter); }
.nodeHeaderGraphic { background-color: var(--nodes-editor-clr-graphic); }
.nodeHeaderExport { background-color: var(--nodes-editor-clr-export); }


.panelButtons {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.panelButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 170px;
    height: 32px;
    border-radius: 18px;
    font-size: 14px;
    font-weight: 700;
}

.panelButton > span {
    margin-right: 8px;
}

.panelButtonDisabled {
    pointer-events: none;
    opacity: 0.4;
    filter: grayscale(100%);
}


.panelButtonSave,
.panelButtonSave:hover {
    color: var(--nodes-editor-clr-save) !important;
    border: 1px solid var(--nodes-editor-clr-save) !important;
}

.panelButtonRun,
.panelButtonRun:hover {
    color: var(--nodes-editor-clr-run) !important;
    border: 1px solid var(--nodes-editor-clr-run) !important;
}

.miniMap {
    top: 24px;
    right: 20px;
    border-radius: 10px;
}