.tabContainer {
    display: flex;
    margin-top: 34px;
    gap: 15px;
}

.tab {
    cursor: pointer;
    font-weight: 700;
    color: var(--gray-title);
}

.selectedTab {
    color: var(--veolet-color);
    text-decoration: underline;
}