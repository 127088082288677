
.header {
    position: fixed;
    width: 100%;
    z-index: 100;
    padding: 17px 24px;
    background: var(--white-color);
    border-bottom: 1px solid var(--border-gray);
}

.wrapper, .flexContainer, .shapesWrapper, .shapesButton {
    display: flex;
}

.wrapper, .flexContainer {
    align-items: center;
}

.wrapper {
    justify-content: space-between;
}

.buttonsWrapper {
    gap: 48px;
}

.borderlessButton {
    padding: 4px 8px;
}

.activeArrow {
    transform: rotate(180deg);
}

.shapesWrapper {
    flex-direction: column;
    width: 195px;
}

.shapesWrapper > ul {
    padding: 0 !important;
}

.shapesWrapper > ul > li {
    padding: 12px !important;
}

.shapesButton {
    align-items: center;
    gap: 10px;
    padding: 12px 5px;
}

.shapesButton > span {
    display: flex;
    align-items: center;
    justify-content: center;
}

.roundButton {
    border: 1px solid var(--veolet-color);
    border-radius: 18px;
    height: 40px;
    padding: 0 30px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 700;
}

.addFieldButton {
    background: transparent;
    color: var(--veolet-color);
}

.addFieldButton:hover {
    color: var(--veolet-color) !important;
}

.switchBlock {
    gap: 16px;
}

.saveButton {
    background: var(--veolet-color);
    color: var(--white-color);
}

.saveButton:hover {
    color: var(--white-color) !important;
    background: var(--veolet-color) !important;
}

.popOver {
    padding: 0;
}



