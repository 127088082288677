.main {
    --graphic-product-data-width: 400px;

    position: fixed;
    z-index: 100;
    left: var(--aside-width);
    top: 0;
    height: 100vh;
    min-width: var(--graphic-product-data-width);
    background: #fff;
    border-right: 1px solid var(--nodes-editor-border-400);
    display: flex;
    flex-direction: column;
    padding: 33px 16px;
    overflow: scroll;
}

.mainRight {
    left: unset;
    right: 0;
    top: 75px;
    height: 100%;
    padding: 27px 16px 108px;
    border-left: 1px solid var(--nodes-editor-border-400);

}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 30px;
}

.headerText {
    font-size: 14px;
    font-weight: 600;
}

.headerTextRight {
    font-size: 18px;
}

.headerCloseButton {
    border: none;
    box-shadow: none;
    padding: 0;
}

.headerCloseButton > svg {
    height: 15px;
    width: 15px;
}

.headerCloseButton > svg > g > path {
    stroke-width: 2px;
}

.search {
    display: flex;
    gap: 8px;
    background-color: #fff;
    padding-bottom: 16px;
}

.searchItem {
    width: 100%;
}

.searchInput,
.searchButton {
    height: 40px;
    border-radius: 20px;
    box-shadow: none;
}

.searchInput {
    border: 1px solid var(--border-color);
    background-color: var(--gray-light);
    padding: 0 16px;
}

.searchInput::placeholder {
    color: var(--gray-veolet);
}

.searchButton {
    width: 40px !important;
    background-color: var(--veolet-color);
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--veolet-color);
}

.searchButton:hover {
    background: var(--veolet-color) !important;
}

.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}

.paginationWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
}

.pagination {
    --pagination-text-400: #959595;
    display: flex;
    align-items: center !important;
    gap: 3px;
}

.pagination > li {
    border: none !important;
    color: var(--pagination-text-400) !important;
    min-width: 20px;
}

.pagination > li:hover {
    background-color: transparent !important;
}

.paginationNavButton,
.paginationNumButton {
    border: none;
    box-shadow: none;
    height: 24px;
    padding: 0;
    color: var(--pagination-text-400) !important;
    background-color: #fff !important;
}

.paginationNumButton:hover {
    color: var(--veolet-color) !important;
    font-weight: 700;
}

.paginationNavButton:hover {
    outline: 1px solid var(--veolet-color) !important;
}

.paginationNumButtonActive {
    color: var(--veolet-color) !important;
    font-weight: 700;
    text-decoration: underline;
    pointer-events: none !important;
}

.scrollPagination {
    width: 75%;
    height: 6px;
    margin-bottom: 20px;
    cursor: pointer;
    background-color: var(--veolet-light);
}

.scrollPaginationInner {
    height: 100%;
    background-color: var(--veolet-color);
}