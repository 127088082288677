.userManagementBlock {
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.innerBlockHeader {
    margin-bottom: 28px;
    display: flex;
    justify-content: space-between;
}
